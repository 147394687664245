import Alpine from 'alpinejs'
import { computePosition, autoUpdate, shift } from '@floating-ui/dom'

export default (() => {
  Alpine.directive('popper', (el, {}, { effect, cleanup }) => {
      const ref = el.closest('[data-popper-parent]')
      let instance = null
      const create = () => {
        computePosition(ref, el, {
          middleware: [shift({ padding: 10 })],
          placement: 'bottom-start',
        }).then(applyStyles(el.style))
      }
      const destroy = () => {
        el.style = ''
        ;['mouseenter', 'focus'].forEach((event) => {
          ref.removeEventListener(event, create)
        })
        instance()
      }

      if (!ref) return

      effect(() => {
        const isDesktop = Alpine.store('bp').isDesktop
        if (!instance && isDesktop) {
          instance = autoUpdate(ref, el, create)

          ;['mouseenter', 'focus'].forEach((event) => {
            ref.addEventListener(event, create)
          })
        } else if (instance) {
          destroy()
        }
      })

      cleanup(() => {
        if (instance) {
          destroy()
        }
      })
    }
  )

  function applyStyles(style) {
    return ({ x = 0, y = 0, strategy = 'absolute' }) =>
      Object.assign(style, {
        position: strategy,
        left: `${x}px`,
        top: `${y}px`,
      })
  }
})()
