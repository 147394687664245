import Alpine from 'alpinejs'

export default (() => {
  if (window.pageSettings.editmode) return

  Alpine.data('panorama', function panorama({ duration = 8000 } = {}) {
    if (typeof duration === 'string') duration = parseInt(duration)

    return {
      loaded: true,
      animation: null,
      invert: false,
      async init() {
        const images = this.$refs['image']
        const getDiff = () => window.innerWidth - images.getBoundingClientRect().width
        const [imagesloaded, gsap] = await Promise.all([
          import('imagesloaded').then((m) => m.default),
          import('gsap').then((m) => m.default),
        ])

        const createAnimation = () => {
          if (this.animation) {
            this.animation.duration(Math.floor(Math.abs((getDiff() / 1000) * (duration / 1000))))
            this.animation.invalidate()
          }
          else
            this.animation = gsap.fromTo(
              this.$refs['image'],
              { x: 0 },
              {
                x: getDiff,
                duration: Math.floor(Math.abs((getDiff() / 1000) * (duration / 1000))),
                ease: 'none', //'power1.inOut',
                repeat: Infinity,
                yoyo: true,
              }
            )
        }

        const trackImages = (cb) => {
          let timeout = null
          return (ignore = false) => {
            if (ignore) return
            imagesloaded(images, () => {
              this.loaded = true
              this.animation?.pause()
              if (timeout) {
                clearTimeout(timeout)
              }
              timeout = setTimeout(() => {
                cb()
                this.animation?.resume()
              }, 800)
            })
          }
        }

        const generator = trackImages(createAnimation)

        generator()

        let w = getDiff()
        try {
          new ResizeObserver(() => {
            generator(w === getDiff())
            w = getDiff()
          }).observe(images)
        } catch (error) {
          // no resize observer possible
          window.addEventListener('resize', () => {
            generator(w === getDiff())
            w = getDiff()
          })
        }
      },
    }
  })
})()
