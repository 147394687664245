export default (async function () {
  if (window.pageSettings.editmode) return

  let groupCount = 0
  const matchGroup = document.querySelectorAll('[data-matchheight-group]')
  if (matchGroup.length <= 0) return

  try {
    const MatchHeight = await import('@tannerhodges/match-height').then(
      (mod) => mod.default
    )
    ;[...matchGroup].forEach((group) => {
      const children = group.querySelectorAll('[data-matchheight]')
      MatchHeight.add(children, 'group-' + groupCount)
      group++
    })
  } catch (error) {
    console.log('Problems loading MatchHeight')
  }
})()
