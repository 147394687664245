import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import PubSub from 'pubsub-js'
import 'config'
import 'modules/popper'
import 'modules/sliders'
import 'modules/accordions'
import 'modules/modals'
import 'modules/videoLightbox'
import 'modules/videos'
import 'modules/lightbox'
import 'modules/loadMore'
import 'modules/dropdown'
import 'modules/form'
import 'modules/responsiveSlider'
import 'modules/panorama'
import 'modules/matchHeight'
import 'modules/masthead'
import 'modules/search'
import 'modules/contentFetcher'
import 'modules/frameFormatter'

Alpine.plugin(intersect)

Alpine.data('menusController', function menusController () {
  return {
    childListener(e) {
      const { target, detail: show } = e
      this.$root.childNodes.forEach(elem => {
        if (elem !== target.closest('li') && elem.nodeName !== '#text') {
          elem.style.display = show ? null : 'none'
        }
      })
      e.stopPropagation()
    },
  }
})

Alpine.data('menu', function menu() {
  return {
    open: false,
    toggle(cb) {
      this.open = !this.open
      if (typeof cb === 'function') cb()
    },
  }
})

window.PubSub = PubSub
window.app = window.app || {}

window.Alpine = Alpine

Alpine.start()