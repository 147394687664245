import Alpine from 'alpinejs'

export default (function () {
  if (window.pageSettings.editmode) return

  let Swiper, Navigation, Autoplay
  const missingDeps = () => [Swiper, Navigation, Autoplay].filter((val) => typeof val !== 'undefined').length === 0

  Alpine.data('responsiveSlider', function (config = {}) {
    return {
      loaded: false,
      finished: false,
      instance: null,
      async init() {
        const component = this.$root
        const prevEl = this.$refs['nav-prev']
        const nextEl = this.$refs['nav-next']

        if (missingDeps()) {
          try {
            ;[{ Swiper, Navigation, Autoplay }] = await Promise.all([
              import('swiper').then(({ Swiper, Navigation, Autoplay }) => ({ Swiper, Navigation, Autoplay })),
              import('swiper/css'),
            ])
          } catch (error) {
            console.log('Problems loading swiper')
          }
        }

        // something went wrong
        if (missingDeps()) return

        this.loaded = true

        this.instance = new Swiper(component, {
          init: false,
          modules: [Navigation, Autoplay],
          navigation: {
            disabledClass: 'pointer-events-none opacity-0',
            nextEl,
            prevEl,
          },
          ...config,
        })

        this.instance.once('init', () => {
          this.finished = true
        })

        // wait for next tick
        setTimeout(() => {
          this.instance.init()
        })
      },
    }
  })
})()
