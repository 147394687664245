import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('masthead', function () {
    return {
      openMenu: false,
      openSearch: false,
      toggleSearch() {
        // do nothing when overlay is open
        if (this.$store.page.hasOpenOverlay) return
        this.openSearch = !this.openSearch
        this.$store.helpers.toggleOverflow(!this.openSearch)
      },
      closeSearch() {
        // do nothing when overlay is open
        if (this.$store.page.hasOpenOverlay) return
        this.openSearch = false
        this.$store.helpers.toggleOverflow(true)
      },
      init() {
        this.$watch('openSearch', (openSearch) => {
          if (openSearch) this.$nextTick(() => this.$refs.inlineSearch.querySelector('[type=\'search\']').focus() )
        })
      },
    }
  })
})()
