import PubSub from 'pubsub-js'

export default (() => {
  if (window.pageSettings.editmode) return

  let accordionsLoaderPromise = null
  const accordionsLoader = (accordions) => {
    if (!accordions || !accordions.length) return
    if (!accordionsLoaderPromise) {
      accordionsLoaderPromise = import(
        /* webpackPrefetch: 0 */ './module'
      )
        .then((mod) => mod.default)
        .catch((err) => console.log(err))
    }
    accordionsLoaderPromise.then((mod) => mod(accordions))
  }

  accordionsLoader(document.querySelectorAll('[data-accordion]'))

  // listen to additions
  PubSub.subscribe('accordions added', () => {
    accordionsLoader(document.querySelectorAll('[data-accordion]'))
  })
})()