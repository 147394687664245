import Alpine from 'alpinejs'

export default (() => {
  if (window.pageSettings.editmode) return

  Alpine.data('slider', function (config = {}) {
    return {
      instance: null,
      arrowOffset: 0,
      pause() {},
      resume() {},
      async init() {
        try {
          (await import('./module').then(mod => mod.default))(config, this)
        } catch (error) {
          console.log('Problems loading swiper')
        }
      },
    }
  })
})()