import Alpine from 'alpinejs'

export default (() => {
  if (window.pageSettings.editmode) return

  Alpine.data('searchFilters', function searchFilters(action) {
    return {
      filters: '',
      refetch: function(state) {
        this.$store.helpers.fetchWithParams(
          action,
          this.$store.helpers.shallowMerge({ isFiltersOnly: true }, state),
          // reuse parent AbortController
          { ...(this.controller ? { signal: this.controller.signal } : {}) }
        ).then(data => {
          this.filters = data
        })
      }
    }
  })
})()