import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('contentFetcher', function (config = {}) {
    const { action, useHistory, state = {}, ajaxParamKeys = [] } = config

    return {
      state,
      action,
      controller: null,
      result: null,
      loading: false,
      resultStack: {},
      appending: {},
      setBaseResult(elem) {
        this.result = elem.innerHTML
      },
      hasQueryKey(key) {
        return !!this.state[key]
      },
      hasQuery(key, value) {
        return this.state[key] === value
      },
      hasStackData(key) {
        return this.resultStack[key] && this.resultStack[key].length > 0
      },
      getStackData(key) {
        return this.resultStack[key]
      },
      cancelRequest() {
        if (this.controller !== null) {
          this.controller.abort()
          this.controller = null
        }
      },
      getContent(params = [], appendKey = false) {
        if ((this.loading || this.appending?.[appendKey]) && this.controller !== null) this.controller.abort()

        let newState = {}
        let resultCache = null
        let resultStackCache = null
        const ajaxUrl = new URL(action, window.location.origin)
        const url = new URL(action, window.location.origin)
        
        // prevent attacks
        if (!(location.hostname === ajaxUrl.hostname || !ajaxUrl.hostname.length))
          return

        if (appendKey) {
          this.appending[appendKey] = true
        }
        else {
          resultCache = this.result
          resultStackCache = this.resultStack
          this.result = null
          this.resultStack = {}
          this.loading = true
        }

        this.controller = new AbortController()

        ajaxUrl.searchParams.append('_ajax', true)
        params.forEach(([key, value]) => {
          if (value) newState[key] = value

          if (ajaxUrl.searchParams.has(key)) {
            if (value) ajaxUrl.searchParams.set(key, value)
            else ajaxUrl.searchParams.delete(key)
          }
          else if (value) ajaxUrl.searchParams.append(key, value)

          if (!ajaxParamKeys.includes(key)) {
            if (url.searchParams.has(key)) {
              if (value) url.searchParams.set(key, value)
              else url.searchParams.delete(key)
            }
            else if (value) url.searchParams.append(key, value)
          }
        })
        this.state = newState

        const abortListener = () => {
          // restart loading indicator for next fetch
          this.loading = true
          this.controller.signal?.removeEventListener('abort', abortListener)
        }
        this.controller.signal?.addEventListener('abort', abortListener)

        return fetch(ajaxUrl, { signal: this.controller.signal })
          .then(res => res.text())
          .then(html => {
            if (appendKey) {
              this.resultStack[appendKey] = [...(this.resultStack[appendKey] ? this.resultStack[appendKey] : []), html]
              this.appending[appendKey] = false
            }
            else {
              this.result = html
              this.loading = false
            }
            this.controller = null
            if (useHistory)
              window.history.pushState(null, '', url);
          })
          .catch((e) => {
            console.warn(e)
            if (!e instanceof DOMException) {
              this.loading = false
            }
            this.controller = null
            this.appending[appendKey] = false
            this.result = resultCache
            this.resultStack = resultStackCache
          })
      },
    }
  })
})()
