import Alpine from 'alpinejs'

export default (() => {
  if (window.pageSettings.editmode) return

  document.documentElement.style.setProperty('--plyr-color-main', '#444')

  Alpine.data('video', function video(config) {
    return {
      videoAspect: 1,
      parentAspect: 1,
      videoHFactor: 1,
      videoWFactor: 1,
      loader: null,
      loaded: false,
      playing: false,
      load() {},
      start() {},
      init() {
        import('./module').then(mod => mod.default(this, config)).catch(e => console.log(e))
      }
    }
  })
})()