export default (() => {
  if (window.pageSettings.editmode) return

  let loadMorePromise = null
  const loadMoreLoader = (loadMore) => {
    if (!loadMore || !loadMore.length) return
    if (!loadMorePromise) {
      loadMorePromise = import(/* webpackPrefetch: 0 */ './module')
        .then((mod) => mod.default(loadMore))
        .catch((err) => console.log(err))
    }
  }

  loadMoreLoader(document.querySelectorAll('[data-loadmore]'))

  // listen to additions
  PubSub.subscribe('load more added', () => {
    loadMoreLoader(document.querySelectorAll('[data-loadmore]'))
  })
})()