export const measures = {
  masthead: {
    height: 0,
  },
  row: {
    md: 768,
    lg: 1100,
    xl: 1280,
  },
}
