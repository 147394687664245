import Alpine from 'alpinejs'

export default (function () {
  if (window.pageSettings.editmode) return

  let wrapper

  Alpine.data('lightbox', function (rawItems = [], autoloadItems = false) {
    let PhotoSwipe, PhotoSwipeUI_Default

    return {
      instance: null,
      lightboxItems: rawItems.map((item) => {
        switch (true) {
          case item.video:
            const video = item.video
            delete item.video
            return {
              ...item,
              html: `
                <div class="flex h-full${item.title ? ' pb-11' : ''}">
                  <video class="max-h-full m-auto" controls><source src="${video}" type="video/mp4"> </video>
                </div>
              `,
            }
        
          default:
            return item
        }
      }),
      openLightbox(index = 0) {
        const options = {
          index,
          history: false,
          shareEl: false,
          bgOpacity: 0.6,
        }
        if (!PhotoSwipe || !wrapper) return

        this.instance = new PhotoSwipe(
          wrapper,
          PhotoSwipeUI_Default,
          this.lightboxItems,
          options
        )
        this.instance.listen('beforeChange', () => {
          ;[...this.instance.container.querySelectorAll('video')].map(
            resetVideo
          )

          this.instance.currItem.container.querySelector('video')?.play()
        })
        this.instance.listen('close', () => {
          ;[...this.instance.container.querySelectorAll('video')].map(
            resetVideo
          )
        })
        this.instance.init()
      },
      preload() {
        if (this.lightboxItems?.[0]?.src) {
          const image = new Image();
          image.src = this.lightboxItems[0].src;
        }
      },
      async init() {
        addLightboxStructure()

        if (autoloadItems) autoLoadLightboxItems(this)

        ;[PhotoSwipe, PhotoSwipeUI_Default] = await Promise.all([
          import('photoswipe').then((mod) => mod.default),
          import('photoswipe/dist/photoswipe-ui-default').then(
            (mod) => mod.default
          ),
          import('photoswipe/dist/photoswipe.css'),
          import('../../styles/containers/lightbox.scss'),
        ]).catch(e => console.log('Error loading lightbox assets', e))
      },
    }
  })

  function autoLoadLightboxItems(context) {
    const items = context.$root.querySelectorAll('[data-lightbox-item]')

    if (items.length <= 0) return

    context.$root.setAttribute('x-on:mouseenter.once', 'preload')
    context.lightboxItems = [...items].map((item, i) => {
      if (!item.getAttribute('data-lightbox-item-ignore')) item.setAttribute('x-on:click', `openLightbox(${i})`)
      return JSON.parse(item.getAttribute('data-lightbox-item'))
    })
  }

  function addLightboxStructure() {
    if (wrapper) return

    wrapper = document.createElement('div')
    wrapper.classList.add('pswp')
    wrapper.setAttribute('tabindex', '-1')
    wrapper.setAttribute('role', 'dialog')
    wrapper.setAttribute('aria-hidden', 'true')
    wrapper.innerHTML = `
        <!-- Background of PhotoSwipe. 
            It's a separate element as animating opacity is faster than rgba(). -->
        <div class="pswp__bg"></div>

        <!-- Slides wrapper with overflow:hidden. -->
        <div class="pswp__scroll-wrap">

            <!-- Container that holds slides. 
                PhotoSwipe keeps only 3 of them in the DOM to save memory.
                Don't modify these 3 pswp__item elements, data is added later on. -->
            <div class="pswp__container">
                <div class="pswp__item"></div>
                <div class="pswp__item"></div>
                <div class="pswp__item"></div>
            </div>

            <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
            <div class="pswp__ui pswp__ui--hidden">

                <div class="pswp__top-bar">

                    <!--  Controls are self-explanatory. Order can be changed. -->

                    <div class="pswp__counter"></div>

                    <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>

                    <button class="pswp__button pswp__button--share" title="Share"></button>

                    <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>

                    <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

                    <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
                    <!-- element will get class pswp__preloader--active when preloader is running -->
                    <div class="pswp__preloader">
                        <div class="pswp__preloader__icn">
                          <div class="pswp__preloader__cut">
                            <div class="pswp__preloader__donut"></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                    <div class="pswp__share-tooltip"></div> 
                </div>

                <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
                </button>

                <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
                </button>

                <div class="pswp__caption font-body">
                    <div class="pswp__caption__center"></div>
                </div>

            </div>

        </div>
    `

    document.body.appendChild(wrapper)
  }

  function resetVideo(video) {
    video.pause()
    video.currentTime = 0
  }
})()
