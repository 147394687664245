import Alpine from 'alpinejs'

const DEFAULT_KEY = 'QUl6YVN5QjBRNGdUaG1zMkp0LTZTZ01ZajR1ZFlLZlZmWE5zcmNj'

export default (() => {
  if (window.pageSettings.editmode) return

  Alpine.data('frameFormatter', function frameFormatter() {
    return {
      async init() {
        const src = this.$root.src
        if (!/(?:youtube(?:-nocookie)?\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\/?\?(?:\S*?&?v\=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/g.test(src)) return

        // todo: change YT-embed size, if needed
        // const getId = await import('get-youtube-id').then(m => m.default)
        // const url = `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${getId(src)}`

        // fetch(url)
        //   .then(resp => {
        //     console.log(resp)
        //     return resp.json()
        //   })
        //   .then(data => {
        //     console.log({data})
        //   })
      },
    }
  })
})()
