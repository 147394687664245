export const toggleCookieBanner = () => {
  const CF = window.CookieFirst
  if (CF) CF.openPanel()
}

export const getConsents = () => {
  const CF = window.CookieFirst
  if (CF) return CF.consent
  return null
}

export const envNeedsConsent = () => {
  return window.pageSettings.needConsent
}

export const waitForConsent = (type, checkEnv = false) => {
  if (checkEnv && !envNeedsConsent()) return new Promise((resolve) => resolve(true))
  return new Promise((resolve) => {
    const consents = getConsents()
    const callbackFnc = () => {
      const consents = getConsents()
      resolve(!!consents?.[type])
      window.removeEventListener('cf_consent', callbackFnc)
      window.removeEventListener('cf_consent_loaded', callbackFnc)
    }

    if (consents) return resolve(!!consents?.[type])
    window.addEventListener('cf_consent', callbackFnc)
    window.addEventListener('cf_consent_loaded', callbackFnc)
  })
}


export const waitForLoad = (cb = () => {}, force = false) => {
  if (force) return new Promise((resolve) => resolve(cb()))
  return new Promise((resolve) => {
    const callbackFnc = () => {
      resolve(cb())
      window.removeEventListener('cf_consent_loaded', callbackFnc)
    }

    window.addEventListener('cf_consent_loaded', callbackFnc)
  })
}

export const createOverlay = ($el) => {
  const overlay = document.createElement('div')
  overlay.innerHTML = `
    <div class="flex justify-center items-center absolute inset-0 text-white">
      Akzeptieren Sie zuerst unsere Cookies, um dieses Video zu sehen.
    </div>
  `

  $el.appendChild(overlay)
}