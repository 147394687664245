import Alpine from 'alpinejs'
import { measures } from 'config/globals'

export default (function () {
  if (window.pageSettings)
    __webpack_public_path__ =
      pageSettings.cacheBusterUrl + __webpack_public_path__
  window.__webpack_public_path__ = __webpack_public_path__

  const vw = () =>
    Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  const vh = () =>
    Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    )
  const isObject = (elem) => typeof elem === 'object' && !Array.isArray(elem) && elem !== null
  const getNestedValue = (obj, key) => {
    if (typeof obj[key] !== 'undefined') return obj[key]
    let result = ''
    for (const prop in obj) {
      if (Object.hasOwnProperty.call(obj, prop)) {
        const element = obj[prop]
        if (isObject(element)) {
          const subresult = getNestedValue(element, key)
          if (subresult !== '') {
            result = subresult
            break
          }
        }
      }
    }

    return result
  }
  // measuring setup
  // track logo height as it should be heighest
  // element in the masthead
  let lastHeight = 0
  const logo = document.getElementById('masthead-logo')
  const setCorrectHeightProp = () => {
    measures.masthead.height = logo.getBoundingClientRect().height
    document.body.style.setProperty('--masthead-height', measures.masthead.height + 'px')
  }
  const setVhUnit = () => {
    const newHeight = window.innerHeight
    // prevent scale when mobile menu changes
    if (Math.abs(lastHeight - newHeight) < 100) return

    const vh = newHeight * 0.01
    lastHeight = newHeight
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
  if (logo) {
    setTimeout(setCorrectHeightProp, 10)

    try {
      new ResizeObserver(setCorrectHeightProp).observe(logo)
    } catch (error) {
      // no resize observer possible
      window.addEventListener('resize', setCorrectHeightProp)
    }
  }

  setVhUnit()
  window.addEventListener('resize', setVhUnit)

  Alpine.store('helpers', {
    lastResizedIframe: null,
    isObject,
    getNestedValue,
    observeHeight(element, cb = () => {}) {
      try {
        new ResizeObserver(cb).observe(element)
      } catch (error) {
        // no resize observer possible
      }
    },
    shallowMerge(...arrayOfObjects) {
      return arrayOfObjects.reduce((all, obj) => ({ ...all, ...obj }), {})
    },
    toggleClasses(el, classes, force) {
      if (typeof classes === 'string') classes = [classes]
      classes.forEach(classname => {
        el.classList.toggle(classname, force)
      })
    },
    toggleOverflow(flag = false) {
      document.body.style.overflow = flag ? null : 'hidden'
    },
    fetchWithParams(url, params, opts = {}) {
      const action = new URL(url, window.location.origin)
      action.searchParams.append('_ajax', true)

      Object.keys(params).forEach(key => {
        const value = params[key]
        if (action.searchParams.has(key)) {
          if (value) action.searchParams.set(key, value)
          else action.searchParams.delete(key)
        }
        else if (value) action.searchParams.append(key, value)
      })

      return fetch(action, opts).then(res => res.text()).catch((e) => {
        console.warn(e)
      })
    },
    frameMessages(e, $root) {
      const eventName = e.data[0]
      const data = e.data[1]
      switch (eventName) {
        case 'setHeight':
          // personio
          $root.style.height = data + 'px'
          // make sure it is not the first load
          if (this.lastResizedIframe === $root) {
            window.scrollTo({
              top: $root.offsetTop - (measures.masthead.height || 0),
              behavior: 'smooth',
            })
          }
          this.lastResizedIframe = $root
          break
      }
    },
  })

  Alpine.store('bp', {
    isDesktop: vw() >= measures.row.lg,
    isSmall: vw() < measures.row.lg,
    init() {
      window.addEventListener('resize', () => {
        this.isDesktop = vw() >= measures.row.lg
        this.isSmall = vw() < measures.row.lg
      })
    },
  })

  Alpine.store('page', {
    documentLoaded: false,
    hasOpenOverlay: false,
    hash: '',
    batch: [],
    scrollTo() {
      const destination = document.getElementById(this.hash)
      if (destination) {
        Alpine.nextTick(() => {
          window.scrollTo({
            top: destination.offsetTop - (measures.masthead.height || 0),
            behavior: 'smooth',
          })
        })
      }
    },
    setHash(hash) {
      this.hash = hash.substring(1)
      const tick = () => {
        history.pushState({}, '', hash)
        if (this.hash.trim().length) {
          this.scrollTo()
        }
      }
      if (!this.documentLoaded) {
        this.batch = [...this.batch, tick]
      }
      else tick()
    },
    init() {
      const hash = window.location.hash

      if (hash?.trim()?.length) {
        window.location.hash = ''
        this.setHash(hash)
      }

      document.documentElement.addEventListener('click', e => {
        const { target } = e
        const currentTarget = target.closest('a[href*="#"]')
        if (!currentTarget) return
        const urlObject = new URL(currentTarget.href)

        // capture hashnavigation only on same host and path
        if (window.location.host !== urlObject.host || window.location.pathname !== urlObject.pathname) return

        e.preventDefault()
        this.setHash(urlObject.hash)
      })

      window.addEventListener('load', () => {
        this.documentLoaded = true
        if (!this.batch.length) return
        this.batch.forEach(fn => fn())
      })
    }
  })

  Alpine.directive('height-spy', (el, { expression }, { evaluate, cleanup }) => {
    const observer = new ResizeObserver(() => {
      evaluate(expression)
    })

    if (observer) observer.observe(el)
    cleanup(() => {
      if (observer) observer.disconnect()
    })
  })
})()
